import { useRef, useEffect } from "react";
import Router from "next/router";

const useRouterEvent = (
  eventName,
  handler,
  { fireImmediately = false } = {}
) => {
  // Create a ref that stores handler
  const savedHandler = useRef<any>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;

    if (fireImmediately) {
      savedHandler.current();
    }
  }, [handler, fireImmediately]);

  useEffect(
    () => {
      // Create event listener that calls handler function stored in ref
      const eventListener = (...params) => savedHandler.current(...params);

      // Add event listener
      Router.events.on(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        Router.events.off(eventName, eventListener);
      };
    },
    [eventName] // Re-run if eventName changes
  );
};

export default useRouterEvent;
