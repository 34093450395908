import React from "react";
import styled, { css } from "styled-components";

import CloseSvg from "../assets/icons/close-svg";
import Tooltip, { TooltipPosition } from "./tooltip";
import Button, { ButtonVariant } from "./button";

type CloseProps = {
  $size?: number;
};
const CloseIcon = styled(CloseSvg)<CloseProps>`
  ${(props) =>
    props.$size &&
    css`
      width: ${props.$size}px;
    `}
`;

type CloseButtonProps = {
  onClick: any;
  color?: string;
  size?: number;
  tooltipText?: string;
  className?: string;
};
const CloseButton = ({
  onClick,
  color,
  size = 22,
  tooltipText,
  className,
}: CloseButtonProps) => {
  const buttonContent = (
    <Button
      {...{ onClick, className }}
      variant={ButtonVariant.GrowableIcon}
      color={color}
    >
      <CloseIcon $size={size} />
    </Button>
  );

  if (!tooltipText) {
    return buttonContent;
  }

  return (
    <Tooltip text={tooltipText} position={TooltipPosition.BottomCenter}>
      <div>{buttonContent}</div>
    </Tooltip>
  );
};

export default CloseButton;
