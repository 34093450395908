import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import Router, { useRouter } from "next/router";
import { useTransition, animated } from "react-spring";

import useTheme from "../hooks/use-theme";
import useRouterEvent from "../hooks/use-router-event";

import CloseButton from "./controls/close-button";

type FlashContainerParams = {
  $bgcolor?: string;
};

const FlashContainer = styled(animated.div)<FlashContainerParams>`
  background: ${(props) => props.$bgcolor};
  box-sizing: border-box;
  color: ${(props) => props.theme.Color.DarkText};
  overflow: hidden;
  padding: 0 ${(props) => props.theme.Padding}px;
  position: fixed;
  top: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  height: ${(props) => props.theme.HeaderHeight}px;
  z-index: ${(props) => props.theme.ZIndex.FlashMessage};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.Padding}px;
  cursor: pointer;
`;

const FLASH_DELAY = 5000;

enum MessageType {
  SUCCESS,
  WARNING,
  FAILURE,
}

const flashMessages = {
  duplicateAdded: {
    message: "Looks like you've already added that one!",
    type: MessageType.WARNING,
  },
  duplicateInWatched: {
    message: "Looks like you already have that one in your Watched List!",
    type: MessageType.WARNING,
  },
  emailConfirmed: {
    message: "Your email has been confirmed!",
    type: MessageType.SUCCESS,
  },
  invalidToken: {
    message: "You have an invalid token. Please try again.",
    type: MessageType.WARNING,
  },
  loggedOut: {
    message: "You successfully logged out.",
    type: MessageType.SUCCESS,
  },
  forgotPasswordSent: {
    message:
      "You should receive an email with password reset instructions shortly.",
    type: MessageType.SUCCESS,
  },
  passwordUpdated: {
    message: "Password updated!",
    type: MessageType.SUCCESS,
  },
  detailsUpdated: {
    message: "Details updated!",
    type: MessageType.SUCCESS,
  },
  sourcesUpdated: {
    message: "Sources updated!",
    type: MessageType.SUCCESS,
  },
  sellDataUpdated: {
    message: "Unpaused your data!",
    type: MessageType.SUCCESS,
  },
  unsellDataUpdated: {
    message: "Paused your data!",
    type: MessageType.SUCCESS,
  },
  accountDeleted: {
    message: "Your account was successfully deleted.",
    type: MessageType.SUCCESS,
  },
  importComplete: {
    message: "Your data was imported successfully!",
    type: MessageType.SUCCESS,
  },
  importConvertComplete: {
    message: "Your account has been converted and your data was imported!",
    type: MessageType.SUCCESS,
  },
  phoneNumberVerified: {
    message: "Your phone number has been verified!",
    type: MessageType.SUCCESS,
  },
  sweepsEntrySuccess: {
    message: "Thanks for your entry!",
    type: MessageType.SUCCESS,
  },
  sweepsEntryFailed: {
    message: "Your Entry Failed",
    type: MessageType.WARNING,
  },
};

/**
 * This sets up Route event listeners to handle dealing with `?flash` messages
 * sent via query params.
 *
 * The logic is to detect on a route change whether the query params contain a
 * flash parameter. If so, we wait for delay milliseconds before
 * shallowly replacing the current route so it does not include the flash query
 * param. In effect, this shows the flash message for a few seconds and then
 * hides it permanently (even after a refresh).
 *
 * Will wait delayBeforeShowing milliseconds before showing the flash message,
 * which is useful for delaying the flash while animateing in to a new page.
 */
const FlashMessage = ({ delayBeforeShowing, delay = FLASH_DELAY }) => {
  const theme = useTheme();

  const timer = useRef<number>();

  const removeImmediately = () => {
    clearTimeout(timer.current);

    const { pathname, query } = Router.router;

    // We make a copy of all current query parameters, and then just delete
    // the flash param.
    const nextQuery = { ...query };
    delete nextQuery.flash;
    const nextHref = { pathname, query: nextQuery };

    // Note the shallow: true.
    // This means we will re-render the page, but all state is preserved
    // and no getInitialProps will be called.
    Router.replace(nextHref, nextHref, { shallow: true });
  };

  // All future client-side route navigations will trigger a check for the
  // flash query parameter.
  useRouterEvent("routeChangeStart", () => {
    // In the case that the user lands on /?flash=something and then before
    // the flash delay has occured, they navigate somewhere else, this will
    // invalidate the flash timer.
    clearTimeout(timer.current);
  });
  useRouterEvent(
    "routeChangeComplete",
    () => {
      const { query } = Router.router;
      if (query && query.flash) {
        // Only set a flash timer if the url contained a flash query param.
        timer.current = setTimeout(removeImmediately, delay);
      }
    },
    {
      // We treat this first load as a route change.
      // We need this so that if the user lands directly on a page url with
      // ?flash=something, the flash will be removed after a few seconds.
      fireImmediately: true,
    }
  );

  const router = useRouter();
  const token = router.query.flash as string;
  const [flashData, setFlashData] = useState(null);

  useEffect(() => {
    let timeout = null;

    if (token) {
      timeout = setTimeout(
        () => setFlashData(flashMessages[token]),
        delayBeforeShowing
      );
    } else {
      setFlashData(null);
    }

    return () => clearTimeout(timeout);
  }, [token, delayBeforeShowing]);

  const transition = useTransition(flashData, {
    config: { mass: 5, tension: 8000, friction: 360 },
    from: { translateY: -100 },
    enter: { translateY: 0 },
    leave: { translateY: -100 },
  });

  const colorForMessageType = useCallback(
    (type: MessageType) => {
      switch (type) {
        case MessageType.SUCCESS:
          return theme.Color.Green;
        case MessageType.WARNING:
          return theme.Color.Yellow;
        case MessageType.FAILURE:
        default:
          return theme.Color.Purple;
      }
    },
    [theme]
  );

  return transition(
    (props, item) =>
      item && (
        <FlashContainer
          style={{
            transform: props.translateY.to((y) => `translateY(${y}%)`),
          }}
          $bgcolor={colorForMessageType(item.type)}
        >
          <CloseContainer>
            <CloseButton
              onClick={removeImmediately}
              color={theme.Color.DarkText}
            />
          </CloseContainer>
          <div>{item.message}</div>
        </FlashContainer>
      )
  );
};

export default FlashMessage;
