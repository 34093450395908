import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
  size: number;
};

const Genres = ({ className, size = 24 }: IconProps) => (
  <Svg
    label="Genres"
    width={size}
    height={size}
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fill="currentColor"
      d="M 10 2 L 10 8.1601562 C 10.236539 8.1355691 10.444277 8.127072 10.6875 8.0917969 C 11.314199 8.0009055 11.939233 7.8370024 12.433594 7.6699219 C 12.354499 7.6230117 12.271206 7.5944598 12.195312 7.5371094 C 11.955966 7.3591814 11.974861 7.2292488 12.050781 7.1679688 L 13.933594 5.2832031 C 13.964252 5.2452679 14.01177 5.2222885 14.074219 5.2382812 C 14.136668 5.2542741 14.213769 5.3100132 14.302734 5.4296875 C 14.448866 5.6230652 14.557087 5.8293477 14.634766 6.0371094 L 15 5.6953125 L 15 10.181641 C 15.305292 10.069435 15.63629 10 16 10 C 17.62091 10 18.687672 11.249872 18.96875 12.541016 C 19.05986 12.959554 18.939069 13.041295 18.798828 12.982422 C 18.167024 12.717216 16.984254 12.701322 16 12.6875 C 15.678604 12.692016 15.337636 12.709092 15 12.722656 L 15 15.234375 L 15 15.253906 C 15 15.253906 14.977535 15.514933 14.933594 15.867188 C 15.265441 15.935252 15.616211 15.982053 15.994141 16 C 21.753592 15.726499 22 9.2148438 22 9.2148438 L 22 2 C 20.771549 3.1494198 16.780812 3.3032891 15.994141 3.2949219 C 15.207469 3.3033218 11.228438 3.1494198 10 2 z M 17.925781 5.2382812 C 17.988235 5.2222819 18.035766 5.2452421 18.066406 5.2832031 L 19.949219 7.1679688 C 20.025088 7.2292867 20.044028 7.3591864 19.804688 7.5371094 C 19.055484 8.1032624 18.119999 8.1650279 17.59375 7.6386719 C 17.067498 7.1123149 17.131085 6.1789202 17.697266 5.4296875 C 17.786225 5.3100173 17.863327 5.2542807 17.925781 5.2382812 z M 13 9.6503906 C 12.346383 9.8566943 11.653431 9.973814 10.974609 10.072266 C 10.661177 10.117723 10.405939 10.130679 10.115234 10.160156 C 10.20222 10.664792 10.362415 11.323075 10.636719 12.015625 C 11.317726 12.097033 11.868945 12.763174 11.990234 13.634766 C 12.033666 13.929812 11.929051 14.01035 11.832031 14 L 11.802734 14 C 12.137565 14.394939 12.529685 14.762675 13 15.070312 L 13 9.6503906 z "
    />
    <path
      fill="currentColor"
      d="M 2 8 L 2 15.214844 C 2 15.214844 2.2346895 21.726499 7.9941406 22 C 13.753592 21.726499 14 15.214844 14 15.214844 L 14 8 C 12.771549 9.1494198 8.7808114 9.3032891 7.9941406 9.2949219 C 7.2074683 9.3033218 3.2284378 9.1494198 2 8 z M 5.5 12 C 6.2443061 12.000073 6.8607972 12.704648 6.9902344 13.634766 C 7.0336665 13.929827 6.9290493 14.01035 6.8320312 14 L 4.1679688 13.998047 C 4.0709586 14.008338 3.9663356 13.929826 4.0097656 13.634766 C 4.1392016 12.704649 4.7556951 11.999924 5.5 12 z M 10.5 12 C 11.244307 12.000075 11.860797 12.704628 11.990234 13.634766 C 12.033666 13.929812 11.929051 14.01035 11.832031 14 L 9.1679688 13.998047 C 9.0709583 14.008337 8.9663356 13.929817 9.0097656 13.634766 C 9.1392016 12.704668 9.7556958 11.999925 10.5 12 z M 5.1269531 16 C 5.1506481 15.99985 5.1748709 16.006488 5.2011719 16.017578 C 5.8329743 16.282779 7.0157477 16.29867 8 16.3125 C 8.9842537 16.298678 10.167024 16.282784 10.798828 16.017578 C 10.939069 15.958705 11.05986 16.040446 10.96875 16.458984 C 10.687672 17.750128 9.6209093 19 8 19 C 6.3790893 19 5.312327 17.750128 5.03125 16.458984 C 4.957223 16.118912 5.0242757 16.000635 5.1269531 16 z "
    />
  </Svg>
);
//   style="fill:#000000;stroke:none;stroke-width:0.00850552;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
////   style="fill:#000000;stroke:none;stroke-width:0.00850552;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
export default Genres;
