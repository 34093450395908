import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) => props.theme.Color.PrimaryColor};
    box-shadow: 0 0 0px 1000px ${(props) =>
      props.theme.Color.GradientColorBottom} inset;
    padding: 0 ${(props) => props.theme.Padding / 2}px;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::selection {
    background: ${(props) =>
      props.theme.Color.Yellow}; /* WebKit/Blink Browsers */
  }

  ::-moz-selection {
    background: ${(props) => props.theme.Color.Yellow}; /* Gecko Browsers */
  }

  ::placeholder {
    color: rgba(255,255,255,0.5);
  }

  body {
    ${(props) => props.theme.FontFamily()}
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
    background-color: ${(props) => props.theme.Color.GradientColorTop};
    color: ${(props) => props.theme.Color.PrimaryColor};
    margin: 0;
    padding: 0;
    height: 100vh;
  }

  a {
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  img, svg {
    vertical-align: top;
  }

  #__next {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tooltip {

    padding: 0 ${(props) => props.theme.Padding}px;
    top: 0 !important;
    justify-content: center;
    pointer-events: none;
    z-index: ${(props) => props.theme.ZIndex.Tooltip};

    display: flex;
  }

  .tooltip-input-info.tooltip-element {
    padding: ${(props) => props.theme.Padding}px;
    width: 200px;
  }

  .tooltip-poster.tooltip-element {
    padding: ${(props) => props.theme.Padding}px;
  }

  .tooltip.tooltip-element {
    top: 1px !important;
  }
`;

export default GlobalStyle;
