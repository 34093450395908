import React from "react";
import Svg from "../svg";
type IconProps = {
  className?: string;
};
const CloseSvg = ({ className }: IconProps) => (
  <Svg
    label="Close"
    width={21}
    height={21}
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M19.965.81l.68.701-9.387 9.375 8.712 8.702-.679.702-8.723-8.714-9.244 9.234-.68-.702 9.233-9.222L.645 1.665l.679-.702 9.244 9.233z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
);

export default CloseSvg;
