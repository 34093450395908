import React from "react";
import Svg from "../svg";
type IconProps = {
  className?: string;
  size: number;
};

const Lenses = ({ className, size = 490 }: IconProps) => (
  <Svg
    label="Lenses"
    width={size}
    height={size}
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill="currentColor"
      d="M23.275,342.449c0,22.096,17.916,40.012,40.012,40.012c8.82,0,16.89-2.94,23.505-7.779l118.58,71.571
		c-0.122,1.256-0.383,2.465-0.383,3.752c0,22.08,17.916,39.996,40.012,39.996c22.096,0,40.011-17.916,40.011-40.012
		c0-1.286-0.26-2.496-0.383-3.752l118.58-71.571c6.615,4.839,14.685,7.779,23.505,7.779c22.096,0,40.012-17.916,40.012-40.012
		c0-16.66-10.198-30.931-24.699-36.949V169.249c14.486-6.018,24.699-20.274,24.699-36.949c0-22.096-17.916-40.012-40.012-40.012
		c-11.071,0-21.085,4.502-28.343,11.775L284.629,43.687c0.122-1.24,0.368-2.435,0.368-3.706C285.012,17.916,267.096,0,245,0
		c-22.096,0-40.012,17.916-40.012,40.012c0,1.271,0.26,2.465,0.368,3.706L91.615,104.094c-7.243-7.273-17.257-11.775-28.328-11.775
		c-22.096,0-40.012,17.916-40.012,40.012c0,16.66,10.198,30.931,24.699,36.949v136.235
		C33.473,311.517,23.275,325.773,23.275,342.449z M406.394,166.615c1.623,0.965,3.246,1.929,5.007,2.649V305.5
		c-3.721,1.547-7.044,3.751-10.091,6.278l-116.666-61.924c0.122-1.24,0.368-2.435,0.368-3.706c0-2.021-0.306-3.966-0.597-5.911
		L406.394,166.615z M260.313,76.945c3.675-1.516,6.952-3.69,9.984-6.186l116.436,61.801c0.015,3.246,0.521,6.355,1.271,9.371
		l-119.392,72.061c-2.572-1.899-5.313-3.553-8.299-4.808V76.945z M260.313,283.098c3.675-1.516,6.967-3.69,9.984-6.186
		l116.773,61.985c-0.107,1.194-0.352,2.343-0.352,3.568c0,2.067,0.306,4.058,0.612,6.048l-117.095,70.682
		c-3.001-2.465-6.263-4.609-9.907-6.125V283.098H260.313z M229.687,413.055c-3.644,1.516-6.906,3.66-9.907,6.125l-117.095-70.682
		c0.306-1.991,0.613-3.981,0.613-6.048c0-1.225-0.245-2.373-0.352-3.568l116.773-61.985c3.032,2.496,6.309,4.67,9.984,6.186v129.973
		H229.687z M103.267,132.576l116.436-61.801c3.032,2.481,6.309,4.655,9.984,6.171v132.254c-2.986,1.24-5.727,2.909-8.299,4.808
		l-119.392-72.061C102.747,138.93,103.252,135.822,103.267,132.576z M78.599,169.264c1.761-0.735,3.384-1.684,5.007-2.649
		l121.995,73.638c-0.291,1.945-0.597,3.889-0.597,5.911c0,1.271,0.26,2.465,0.368,3.706L88.705,311.793
		c-3.047-2.527-6.385-4.732-10.091-6.278V169.264H78.599z"
    />
  </Svg>
);

export default Lenses;
