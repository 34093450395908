import React from "react";
import styled from "styled-components";

import LensesSvg from "../components/assets/discovery-types/lenses";
import GenresSvg from "../components/assets/discovery-types/genres";
import HistorySvg from "../components/assets/discovery-types/history";

const Strong = styled.strong`
  ${(props) => props.theme.FontHeaderBold()}
`;

type DiscoveryType = {
  name: string;
  description?: React.ReactNode;
  id: string;
  Icon: any; // TODO:
  blueprint: {
    type: string;
    probability: number;
    params?: object;
  };
};
export const DISCOVERY_TYPES: DiscoveryType[] = [
  {
    name: "Lenses",
    description: (
      <span>
        <Strong>Lenses</Strong> identify patterns in your profile and make
        interesting connections to other content
      </span>
    ),
    id: "lenses",
    Icon: LensesSvg,
    blueprint: {
      type: "GraphLens",
      probability: 5,
    },
  },
  {
    name: "History",
    description: (
      <span>
        <Strong>History</Strong> makes recommendations based on specific titles
        you have watched
      </span>
    ),
    id: "history",
    Icon: HistorySvg,
    blueprint: {
      type: "BasedOnItem",
      params: {
        nameGenerator: "Because You Watched $1",
      },
      probability: 5,
    },
  },

  {
    name: "Genres",
    description: (
      <span>
        <Strong>Genres</Strong> makes recommendations based on your top genres
      </span>
    ),
    id: "genres",
    Icon: GenresSvg,
    blueprint: {
      type: "TopGenres",
      probability: 5,
    },
  },
];
