import { css, DefaultTheme } from "styled-components";

const DarkText = "#0E123D";
const PrimaryText = "#FFFFFF";
const PrimaryHoverColor = "#FEC601";
const TransparentLight = "rgba(255, 255, 255, 0.2)";
const TooltipBackgroundColor = "#2b2e7b";
const TransparentBlack = "rgba(0, 0, 0, 0.5)";
const SecondaryColorOnLight = "#F3F3F6";

const Color = {
  Pink: "#D81771",
  Green: "#56E39F",
  SubtleGreen: "#73BFB8",
  Yellow: PrimaryHoverColor,

  Purple: "#693668",
  GradientColorTop: "#3F51B5",
  GradientColorBottom: "#8034B2",
  HeroGradientPrimary: "#161d62",
  HeroGradientSecondary: "#5a34b2",
  SearchColor: TooltipBackgroundColor,
  TooltipBackgroundColor: TooltipBackgroundColor,

  PrimaryColor: PrimaryText,
  SecondaryColor: "rgba(255, 255, 255, 0.7)",
  SecondaryColorOnLight: "#948FAD",
  SecondaryBgColor: SecondaryColorOnLight,
  BorderColor: "rgba(255, 255, 255, 0.4)",
  DarkText: DarkText,
  TextShadow: "#000000",
  SecondaryButtonColorOnLight: SecondaryColorOnLight,
  PosterDefaultColor: "rgba(255, 255, 255, 0.1)",
  UnchangeableIconColor: PrimaryText,
  SpinnerOnDark: TransparentBlack,

  TransparentLight: TransparentLight,
  TransparentDark: "rgba(46, 41, 78, 0.95)",

  ButtonColor: "#23F0C7",
  ButtonHover: PrimaryHoverColor,
  ButtonTextColor: DarkText,
  ButtonHoverTextColor: DarkText,
  LinkHoverTextColor: PrimaryHoverColor,

  GaugeColor: TransparentLight,
  CardBgColor: PrimaryText,

  PosterIconBackgroundColor: "rgba(0, 0, 0, 0.7)",
  PosterRatingBackgroundColor: "rgba(0, 0, 0, 0.9)",

  // HeaderColor: "#1B998B",
  // ErrorColor: "#B80C09",
};

// Never use these colors outside of charts
const ChartColor = {
  Color1: "#E83C38",
  Color2: "#08D4F4",
  Color3: "#FF4B9B",
  Color4: "#3170DE",
  Color5: "#A83EFF",
  Color6: "#31CAF8",
  Color7: "#18CB1E",
  Color8: "#FF8551",
  Color9: "#FEFF00",
  Color10: "#FF78B2",
};

const ChartColorArray = [
  ChartColor.Color1,
  Color.Green,
  ChartColor.Color2,
  ChartColor.Color3,
  Color.Yellow,
  ChartColor.Color4,
  ChartColor.Color5,
  ChartColor.Color6,
  Color.Pink,
  ChartColor.Color7,
  ChartColor.Color8,
  ChartColor.Color9,
  ChartColor.Color10,
];

/*-----------------------------------------
Media Queries
------------------------------------------*/
const DeviceSize = {
  MinWidthSmall: 400,
  PhoneMaxWidth: 500,
  MaxWidthSmall: 740,
  MobileMaxWidth: 900,

  // Rename this so it works with desktop navigation too
  LaptopMaxWidth: 1080,
  MaxWidthSize: 1440,
};

const buildBetweenMediaQuery = (min, max) => {
  return `only screen and (min-width: ${min}px) and (max-width: ${max}px)`;
};

const buildMediaQuery = (size) => {
  return {
    MIN: `only screen and (min-width: ${size + 1}px)`,
    MAX: `only screen and (max-width: ${size}px)`,
  };
};

const MediaQuery = {
  MobileS: buildMediaQuery(DeviceSize.MinWidthSmall), // 375
  MobileL: buildMediaQuery(DeviceSize.PhoneMaxWidth), // 500
  TabletS: buildMediaQuery(DeviceSize.MaxWidthSmall), // 740
  TabletL: buildMediaQuery(DeviceSize.MobileMaxWidth), // 900
  Laptop: buildMediaQuery(DeviceSize.LaptopMaxWidth), // 1080
  // Desktop: buildMediaQuery(DeviceSize.MaxWidthSize), // 1440,
  BetweenWidths: (min, max) => buildBetweenMediaQuery(min, max),
};

const Padding = 10;

const theme: DefaultTheme = {
  Color,
  ChartColor,
  ChartColorArray,

  /*-----------------------------------------
  Size Variables
------------------------------------------*/
  Padding,
  OutsidePagePadding: Padding * 5,
  DefaultPosterPadding: Padding,
  SmallPosterPadding: Padding / 2,
  PagePadding: Padding * 5,
  MobilePagePadding: Padding,
  BorderRadiusLarge: 10,
  BorderRadiusSmall: 5,
  ControlHeight: 40,
  InputHeight: 50,
  HeaderHeight: 60,
  PosterTextHeight: 72,
  TooltipArrowSize: 8,
  PosterHeightRatio: 150,
  BannerHeightRatio: 400 / 7,
  NavItemHeight: 22,
  IconSize: 18,

  ...DeviceSize,
  MediaQuery,

  SectionSpacing: Padding * 4,
  CarouselSidePadding: 50,
  NumPostersPerCluster: 6,

  ZIndex: {
    Negative: -1,
    Button: 2,
    Search: 3,
    Claim: 4,
    Navigation: 5,
    Modal: 6,
    Tooltip: 7,
    FlashMessage: 8,
    PosterOverlayLevel2: 2,
  },

  FontFamily() {
    return css`
      font-family: "Maven Pro", sans-serif;
      font-weight: 400;
    `;
  },

  FontHeader() {
    return css`
      font-family: "josefin sans", Arial, sans-serif;
      font-weight: 400;
      padding-top: 0.28em;
    `;
  },

  FontHeaderBold() {
    return css`
      font-family: "josefin sans", Arial, sans-serif;
      font-weight: 700;
      padding-top: 0.4em;
    `;
  },

  H1FontSize() {
    return css`
      font-size: 28px;

      @media ${theme.MediaQuery.TabletS.MAX} {
        font-size: 24px;
      }
    `;
  },

  H2FontSize() {
    return css`
      font-size: 18px;

      @media ${theme.MediaQuery.TabletS.MAX} {
        font-size: 18px;
      }
    `;
  },

  MaxWidth(size) {
    return css`
      box-sizing: border-box;
      margin: 0 auto;
      padding-right: ${theme.PagePadding}px;
      padding-left: ${theme.PagePadding}px;
      width: 100%;
      max-width: ${size ? size : theme.MaxWidthSize}px;
    `;
  },

  TopBarSideWidth() {
    return css`
      width: 240px;

      @media ${theme.MediaQuery.Laptop.MAX} {
        width: 100px;
      }
    `;
  },

  AppearanceNone() {
    return css`
      appearance: none;
      background: transparent;
      border: 0;
      border-radius: 0;
      padding: 0;

      &:focus {
        outline: none;
      }
    `;
  },

  Gradient() {
    return css`
      background: ${theme.Color.GradientColorTop};
      background-image: linear-gradient(
        ${theme.Color.GradientColorTop} 0%,
        ${theme.Color.GradientColorTop} ${theme.HeaderHeight * 2}px,
        ${theme.Color.GradientColorBottom} 70%
      );
      background-repeat: no-repeat;

      @media ${theme.MediaQuery.Laptop.MAX} {
        background-image: linear-gradient(
          ${theme.Color.GradientColorTop} 0%,
          ${theme.Color.GradientColorTop} ${theme.HeaderHeight * 2}px,
          ${theme.Color.GradientColorBottom} 70%
        );
      }
    `;
  },
  GradientFlipped() {
    return css`
      background: ${theme.Color.GradientColorTop};
      background-image: linear-gradient(
        ${theme.Color.GradientColorBottom} 0%,
        ${theme.Color.GradientColorTop} 70%
      );
    `;
  },
  BackgroundBlur(blur = 20) {
    return css`
      @supports (backdrop-filter: none) {
        backdrop-filter: blur(${blur}px);
      }

      @supports not (backdrop-filter: none) {
        background-color: rgba(0, 0, 0, 0.3);
      }

      /* Firefox does not use @supports */

      @-moz-document url-prefix() {
        background-color: ${theme.Color.GradientColorTop};
      }
    `;
  },
  LineClamp(lines) {
    return css`
      position: relative;
      -webkit-line-clamp: ${lines};

      ${lines > 1 &&
      css`
        padding: 0 !important;
        height: calc(1.1em * ${lines});
      `}

      /* stylelint-disable */
      /* .line-clamp can’t be an inline element */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      /* stylelint-enable */

      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;

      /* fade for non-webkit browsers */
      &:after {
        content: "";
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          ${theme.Color.GradientColorTop} 75%
        );
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
        width: 50%;
        height: calc(1em * 1.2);

        /* hide on webkit browsers */

        @supports (-webkit-line-clamp: 1) {
          display: none;
        }
      }
    `;
  },
};

export default theme;
