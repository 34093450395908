type MediaTypes = {
  name: string;
  id: string;
  description: string;
};
export const MEDIA_TYPES: MediaTypes[] = [
  {
    name: "TV Shows",
    id: "ShowSeries",
    description: "Filter by TV Shows",
  },
  {
    name: "Movies",
    id: "Movie",
    description: "Filter by Movies",
  },
];
