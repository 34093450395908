import React from "react";
import { useSpring, animated } from "react-spring";
import { ThemeProvider } from "styled-components";

import styled, { css } from "styled-components";

import WithTooltip, { TooltipPosition } from "./with-tooltip";
import useTheme from "../../hooks/use-theme";
import { useResponsive } from "../responsive-provider";

export { TooltipPosition };

type TooltipContainerProps = {
  $position: TooltipPosition;
};
const TooltipContainer = styled(animated.div)<TooltipContainerProps>`
  background-color: ${(props) => props.theme.Color.TooltipBackgroundColor};
  border-radius: ${(props) => props.theme.BorderRadiusLarge}px;
  padding: ${(props) => props.theme.Padding}px;
  max-width: 300px;
  height: auto;
  text-align: center;

  &::after {
    content: "";

    /* Using color here allows us to inherit it using the specific border location color, i.e., border-bottom-color, which we define in global-styles */
    color: ${(props) => props.theme.Color.TooltipBackgroundColor};
    border-right: ${(props) => props.theme.TooltipArrowSize}px solid transparent;
    border-left: ${(props) => props.theme.TooltipArrowSize}px solid transparent;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    width: 0;
    height: 0;
  }

  /* stylelint-disable */
  ${(props) =>
    (props.$position === TooltipPosition.BottomLeft ||
      props.$position === TooltipPosition.BottomCenter ||
      props.$position === TooltipPosition.BottomRight) &&
    css`
      margin-top: ${props.theme.TooltipArrowSize * 1.5}px;

      &::after {
        border-bottom: ${props.theme.TooltipArrowSize}px solid;
        border-bottom-color: inherit;
        top: ${props.theme.TooltipArrowSize * -1}px;
      }
    `}

  ${(props) =>
    (props.$position === TooltipPosition.TopLeft ||
      props.$position === TooltipPosition.TopCenter ||
      props.$position === TooltipPosition.TopRight) &&
    css`
      margin-bottom: ${props.theme.TooltipArrowSize * 1.5}px;

      &::after {
        border-top: ${props.theme.TooltipArrowSize}px solid;
        border-top-color: inherit;
        bottom: ${props.theme.TooltipArrowSize * -1}px;
      }
    `} /* stylelint-enable */
`;

type TooltipContentProps = {
  text: React.ReactNode;
  width: string | number;
  position: TooltipPosition;
};
const TooltipContent = ({ text, width, position }: TooltipContentProps) => {
  const { scale, opacity } = useSpring({
    config: { mass: 1, tension: 2000, friction: 120 },
    opacity: 1,
    scale: 1,
    from: { opacity: 0, scale: 0 },
  });
  return (
    <TooltipContainer
      $position={position}
      style={
        {
          opacity,
          transform: scale.to((s) => `scale(${s})`),
          width,
        } as any
      }
    >
      {text}
    </TooltipContainer>
  );
};

type TooltipProps = {
  text: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  position?: TooltipPosition;
  width?: string | number;
};
const Tooltip = ({
  text,
  children,
  className,
  position = TooltipPosition.TopCenter,
  width = "auto",
}: TooltipProps) => {
  const theme = useTheme();
  const { isTouchDevice } = useResponsive();

  return (
    <WithTooltip
      content={
        <ThemeProvider theme={theme}>
          <TooltipContent text={text} width={width} position={position} />
        </ThemeProvider>
      }
      className={`tooltip-default-styles ${className}`}
      {...{ position, isTouchDevice }}
    >
      {children}
    </WithTooltip>
  );
};

Tooltip.TooltipContainer = TooltipContainer;
export default Tooltip;
