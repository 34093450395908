import React from "react";
import Svg from "../svg";
type IconProps = {
  className?: string;
  size: number;
};

const History = ({ className, size = 24 }: IconProps) => (
  <Svg
    label="History"
    width={size}
    height={size}
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C10.2903875,21 8.64944804,20.5217936 7.23080614,19.6337823 C6.65294072,19.2720624 6.11769706,18.8456754 5.63566512,18.3635872 C5.15289898,17.8807646 4.72601217,17.3445768 4.36400875,16.7656632 C3.47740079,15.3478054 3,13.7081751 3,12 C3,11.725846 3.01227986,11.4530822 3.03669655,11.1822711 C3.07389172,10.769731 3.43847425,10.4654537 3.85101443,10.5026489 C4.2635546,10.5398441 4.56783184,10.9044266 4.53063667,11.3169668 C4.51025648,11.5430081 4.5,11.7708281 4.5,12 C4.5,13.4248663 4.89726709,14.7892778 5.63582603,15.9703766 C5.93763522,16.4530279 6.2936779,16.9002326 6.69638725,17.3029889 C7.09848441,17.7051331 7.54490219,18.0607594 8.02668093,18.362333 C9.20843557,19.1020627 10.5739361,19.5 12,19.5 C16.1421356,19.5 19.5,16.1421356 19.5,12 C19.5,7.85786438 16.1421356,4.5 12,4.5 C9.60270786,4.5 7.39952516,5.63281093 5.99774512,7.50196302 L8.75418677,7.50209339 C9.16840034,7.50209339 9.50418677,7.83787982 9.50418677,8.25209339 C9.50418677,8.63178915 9.22203289,8.94558435 8.85595733,8.99524677 L8.75418677,9.00209339 L4.25,9.00209339 C3.87030423,9.00209339 3.55650904,8.71993951 3.50684662,8.35386394 L3.5,8.25209339 L3.5,3.75209339 C3.5,3.33787982 3.83578644,3.00209339 4.25,3.00209339 C4.62969577,3.00209339 4.94349096,3.28424727 4.99315338,3.65032283 L5,3.75209339 L4.99900166,6.34348083 C6.68586937,4.25603382 9.23627771,3 12,3 Z M11.25,7 C11.6295,7 11.9434583,7.28233333 11.9931493,7.64827431 L12,7.75 L12,12 L14.25,12 C14.664,12 15,12.336 15,12.75 C15,13.1295 14.7176667,13.4434583 14.3517257,13.4931493 L14.25,13.5 L11.25,13.5 C10.8705,13.5 10.5565417,13.2176667 10.5068507,12.8517257 L10.5,12.75 L10.5,7.75 C10.5,7.336 10.836,7 11.25,7 Z"
    ></path>
  </Svg>
);

export default History;
