import React, { createContext } from "react";

import useLocalStorage from "@/hooks/use-local-storage";

import { SOURCES } from "@/util/sources";
import { DISCOVERY_TYPES } from "@/util/discovery-types";
import { MEDIA_TYPES } from "@/util/media-types";

type SettingsObject = {
  selectedSources: string[];
  selectedDiscoveryTypes: string[];
  selectedMediaTypes: string[];
};

type UserSettingsContextType = {
  settings: SettingsObject;
  setSettings: (SettingsObject) => SettingsObject;
};

const defaultSettings = {
  selectedSources: SOURCES.map((s) => s.mhid),
  selectedDiscoveryTypes: DISCOVERY_TYPES.map((d) => d.id),
  selectedMediaTypes: MEDIA_TYPES.map((m) => m.id),
};
export const UserSettingsContext = createContext<UserSettingsContextType>({
  settings: defaultSettings,
  setSettings: () => defaultSettings,
});

const INITIAL_SETTINGS = {
  selectedSources: SOURCES.map((s) => s.mhid),
  selectedDiscoveryTypes: DISCOVERY_TYPES.map((d) => d.id),
  selectedMediaTypes: MEDIA_TYPES.map((m) => m.id),
};
export const UserSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useLocalStorage(
    "userSettings",
    INITIAL_SETTINGS
  );

  return (
    <UserSettingsContext.Provider
      value={{
        settings,
        setSettings,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const checkHasValidSource = ({ selectedSources, item }) => {
  const itemSourceMhids = new Set(item.sources.map((s) => s.mhid));
  return selectedSources.filter((o) => itemSourceMhids.has(o)).length > 0;
};
