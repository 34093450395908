import React from "react";

type SvgProps = {
  label?: string;
  role?: string;
  preserveAspectRatio?: any;
  width?: any;
  height?: any;
  viewboxHeight?: any;
  viewboxWidth?: any;
  children?: React.ReactNode;
  className?: string;
};

const Svg = ({
  label,
  role = "presentation",
  preserveAspectRatio = null, //must pass in the type
  width,
  height,
  viewboxWidth,
  viewboxHeight,
  children,
  className,
}: SvgProps) => {
  const props =
    preserveAspectRatio && preserveAspectRatio !== "none"
      ? {
          preserveAspectRatio: preserveAspectRatio,
          height: "100%",
          width: "100%",
        }
      : {
          width,
          height,
        };

  // aria-label must be first thing that follows svg tag
  // `role` of presentation will tell readers not to announce everything inside of the svg
  // if you want to announce everything inside, switch to role="group"
  let screenReaderLabels = {};
  if (label) {
    screenReaderLabels = {
      "aria-label": label,
    };
  }

  return (
    <svg
      {...screenReaderLabels}
      role={role}
      {...props}
      viewBox={`0 0 ${viewboxWidth || width} ${viewboxHeight || height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      {children}
    </svg>
  );
};

export default Svg;
