import React, { Component } from "react";
import ReactDOM from "react-dom";

// Tooltip doesn't work in a server environment, so only require it if
// we have a document.
let TetherTooltip;
if (typeof document !== "undefined") {
  TetherTooltip = require("tether-tooltip");
}

export enum TooltipPosition {
  TopLeft = "top left",
  LeftTop = "left top",
  LeftMiddle = "left middle",
  LeftBottom = "left bottom",
  BottomLeft = "bottom left",
  BottomCenter = "bottom center",
  BottomRight = "bottom right",
  RightBottom = "right bottom",
  RightMiddle = "right middle",
  RightTop = "right top",
  TopRight = "top right",
  TopCenter = "top center",
}

const TooltipConstraints = [
  {
    to: "window",
    attachment: "together",
    pin: true,
  },
];

type WithTooltipProps = {
  className?: string;
  position?: TooltipPosition;
  content?: any;
  isTouchDevice: boolean;
};

/**
 * A wrapper to set around components that must have a tooltip
 * The tooltip knows how to reposition itself according to constraints on scroll/resize...
 * See http://github.hubspot.com/tooltip/
 */
export default class WithTooltip extends Component<WithTooltipProps> {
  tetherTooltip?: any;
  componentDidUpdate(prevprops) {
    if (
      this.props.className !== prevprops.className ||
      this.props.position !== prevprops.position
    ) {
      this.resetTooltipForCurrentProps();
    } else if (this.props.content !== prevprops.content) {
      this.resetTooltipContent();
    }
  }

  componentWillUnmount() {
    this.destroyTooltip();
  }

  handleMouseEnter = () => {
    this.resetTooltipForCurrentProps();
  };

  handleMouseLeave = () => {
    this.destroyTooltip();
  };

  resetTooltipForCurrentProps() {
    if (!TetherTooltip) {
      return; // Bail out in server environment
    }

    if (this.props.isTouchDevice) {
      return;
    }

    // This used to be in a setTimeout().
    // DEB: Removed this because the setTimeout fires after the tooltip unmounts
    // and causes lots of warnings. Things seem to work fine without this being delayed.
    // May return as an issue if we start having csstransition groups.
    //
    // The timeout is required because otherwise TetherTooltip messes up with animations entering (ReactCSSTransitionGroup)
    // setTimeout(() => {
    this.destroyTooltip();

    const target = ReactDOM.findDOMNode(this);

    let tooltipCls = "tooltip-theme-arrows";
    if (this.props.className) {
      tooltipCls = this.props.className;
    }

    this.tetherTooltip = new TetherTooltip({
      target: target,
      position: this.props.position || "bottom center",
      content: " ",
      classes: tooltipCls,
      tetherOptions: {
        constraints: TooltipConstraints,
      },
      remove: true, //causes it to be removed from DOM when it is closed, then added again when it is opened
    });

    // We mount the tooltip content ourselves because we want to be able to mount React content as tooltip
    const tooltipContentNode = this.getTetherTooltipNode();
    const innerNode = tooltipContentNode.querySelector(".tooltip-content");

    if (React.isValidElement(this.props.content)) {
      ReactDOM.render(this.props.content, innerNode);
    } else {
      innerNode.innerHTML = this.props.content;
    }
    // }, 0);
  }

  resetTooltipContent() {
    if (!TetherTooltip) {
      return; // Bail out in server environment
    }

    if (this.props.isTouchDevice) {
      return;
    }

    if (!this.tetherTooltip) {
      return this.resetTooltipForCurrentProps();
    }

    // We mount the tooltip content ourselves because we want to be able to mount React content as tooltip
    const tooltipContentNode = this.getTetherTooltipNode();
    const innerNode = tooltipContentNode.querySelector(".tooltip-content");

    if (React.isValidElement(this.props.content)) {
      ReactDOM.render(this.props.content, innerNode);
    } else {
      innerNode.innerHTML = this.props.content;
    }
    this.tetherTooltip.position();
  }

  destroyTooltip() {
    if (this.props.isTouchDevice) {
      return;
    }

    if (this.tetherTooltip) {
      this.tetherTooltip.destroy();
      delete this.tetherTooltip;
    }
  }

  // It may return nothing if the tooltip is already removed from DOM
  getTetherTooltipNode() {
    return (
      this.tetherTooltip &&
      this.tetherTooltip.drop &&
      this.tetherTooltip.drop.drop
    );
  }

  render() {
    if (typeof this.props.children === "string") {
      return (
        <span
          onMouseEnter={
            !this.props.isTouchDevice ? () => this.handleMouseEnter : null
          }
          onMouseLeave={
            !this.props.isTouchDevice ? () => this.handleMouseLeave : null
          }
        >
          {this.props.children}
        </span>
      );
    } else {
      const child = React.Children.only(this.props.children) as any;
      return React.cloneElement(child, {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
      });
    }
  }
}
