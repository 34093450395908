import React from "react";
import dynamic from "next/dynamic";
import { NextSeo } from "next-seo";
import styled, { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import { AppProps } from "../@types/next-overrides";

import theme from "../theme/theme";

import { ReactPageTransitions } from "@autum/react-page-transitions";
import Analytics from "../components/analytics";
import GlobalStyle from "../components/global-style";
import FlashMessage from "../components/flash-message";
import useAuth from "@/hooks/use-auth";
import { AuthProvider } from "@/util/auth/auth";
import { generateLoginRedirect } from "@/util/auth/redirects";
import { appName, appTagline, appDescription } from "../util/common-text";
import { ResponsiveProvider } from "../components/responsive-provider";
import { UserActionStateProvider } from "../components/user-action-state";
import { UserSettingsProvider } from "../components/user-settings";

const Navigation = dynamic(
  () => import("../components/navigation/navigation-renderer"),
  {
    ssr: false,
  }
);

if (process.env.NODE_ENV === "production") {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.SENTRY_DSN,
  });
}

const ComponentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GradientBg = styled.div`
  ${(props) => props.theme.Gradient()}
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.theme.ZIndex.Negative};

  /* So the scroll still works when cursor is over gradient */
  pointer-events: none;
`;

const precheckComponent = ({ Component, auth, router }) => {
  if (Component.requireAuthenticated) {
    if (!auth.user) {
      // In the event that a page requires authentication, but the user is not
      // logged in, we direct them to the Login page.
      // We also provide the login page with a returnTo query parameter,
      // so that after log in they are redirected to their incoming page.
      return { redirectTo: generateLoginRedirect(router) };
    }
  } else if (Component.requireNotAuthenticated) {
    if (auth.user) {
      // In the event that a page requires a non-authenticated user, but the
      // user is logged in, we just return them to the homepage.
      return { redirectTo: "/?immediate=true" };
    }
  }

  return {};
};

const PageComponent = ({ Component, pageProps, router, err }: AppProps) => {
  // Pages can require authentication by specifying:
  // `Page.requireAuthenticated = true;`
  // Pages can require the user to NOT be authenticated by specifying:
  // `Page.requireNotAuthenticated = true;`
  // These are mutually exclusive.

  const auth = useAuth();

  const { redirectTo } = precheckComponent({
    Component,
    auth,
    router,
  });

  let content;
  if (redirectTo) {
    if (global.window) {
      router.replace(redirectTo);
    }
  } else {
    const key = Component.pageKey || router.asPath.split("?")[0];
    // We need to pass err as a workaround for https://github.com/vercel/next.js/issues/8592
    content = <Component {...pageProps} err={err} key={key} />;
  }

  return (
    <ComponentContainer>
      <ReactPageTransitions router={router}>{content}</ReactPageTransitions>
      <Navigation />
    </ComponentContainer>
  );
};

const TRANSITION_OUT_MS = 400;

const AutumApp = (props: AppProps) => {
  if (!props.pageProps.session) {
    console.error(
      "Page Props did not return a session. Did you forget a getServerSideProps?"
    );
  }

  return (
    <>
      <NextSeo
        title={`${appName} - ${appTagline}`}
        description={appDescription}
        canonical="https://autum.com/"
        openGraph={{
          url: "https://autum.com/",
          title: `${appName} - ${appTagline}`,
          description: appDescription,
          images: [
            {
              url: "https://autum.com/assets/share-image.jpg",
              width: 800,
              height: 400,
            },
          ],
          site_name: appName,
          type: "website",
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
      />
      <ThemeProvider theme={theme}>
        <AuthProvider initialSession={props.pageProps.session}>
          <UserSettingsProvider>
            <UserActionStateProvider>
              <ResponsiveProvider>
                <GlobalStyle />
                <Analytics />
                <GradientBg />
                <FlashMessage delayBeforeShowing={TRANSITION_OUT_MS * 2} />
                <PageComponent {...props} />
              </ResponsiveProvider>
            </UserActionStateProvider>
          </UserSettingsProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
};

export default AutumApp;
